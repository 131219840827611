'use client'

import Cookie from 'js-cookie'

import { ReactNode, useEffect } from 'react'
import { SessionProvider } from 'next-auth/react'

import { GrowthBook } from '@growthbook/growthbook'
import { GrowthBookProvider } from '@growthbook/growthbook-react'

import { usePathname } from 'next/navigation'
import { ManagedUIContext } from './_components/ui/context'

import { NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY } from '@utils/client-side-config'
import { COOKIE_FEATURES, COOKIE_VISITOR_ID } from '@utils/constants'
import { trackABExperiment } from '@utils/gtm'
import ReactDOM from 'react-dom'

const ELBRUS_PATHS = ['blog', 'community', 'sustainability']

const checkStringContains = (
  currentPath: string,
  pathsToCheck: string[] = []
): boolean => {
  return pathsToCheck.some((pattern) => currentPath.includes(pattern))
}

const growthBook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    trackABExperiment(experiment.key, result.variationId)
  },
})

export default function App({ children }: { children: ReactNode }) {
  // Preconnect links are not supported by Next JS Metadata yet, so we need to add them here
  // https://nextjs.org/docs/14/app/api-reference/functions/generate-metadata#resource-hints
  ReactDOM.preconnect('https://ctfassets.net')
  ReactDOM.preconnect('https://images.ctfassets.net')
  ReactDOM.preconnect('https://static.zdassets.com')

  const pathname = usePathname()

  useEffect(() => {
    if (checkStringContains(pathname, ELBRUS_PATHS)) {
      document.body.classList?.add('elbrus-headless')
    }
  }, [pathname])

  // Initialize GrowthBook
  useEffect(() => {
    const features = JSON.parse(Cookie.get(COOKIE_FEATURES) || '{}')
    const visitorId = Cookie.get(COOKIE_VISITOR_ID)

    growthBook.setAttributes({
      id: visitorId,
      country: NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY,
    })

    growthBook.setFeatures(features)
  }, [pathname])

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <SessionProvider>
        <ManagedUIContext>{children}</ManagedUIContext>
      </SessionProvider>
    </GrowthBookProvider>
  )
}
